import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';
import { ThemeColors } from '@wyjehau/theme';

import { useLeafletMap } from '../../leaflet-map-context';
import { LocationIcon } from '../../icons/assets/location-icon';

const Button = styled.button({
	background: ThemeColors.blue3,
	width: 36,
	height: 36,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'white',
	borderRadius: 10,
	cursor: 'pointer',
	padding: 0,
	'& svg': {
		width: 21,
		height: 21,
	},
});

export const AutoGeolocateButton = (
	props: HTMLAttributes<HTMLButtonElement>,
) => {
	const map = useLeafletMap();

	const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				map?.flyTo([pos.coords.latitude, pos.coords.longitude], 13);
			},
			(err) => {
				alert(`Błąd: ${err.message}`);
			},
		);
	};

	if (!map) {
		return null;
	}

	return (
		<Button {...props} onClick={handleClick}>
			<LocationIcon />
		</Button>
	);
};
