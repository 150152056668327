import { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';

import { CloseIcon } from '../../icons/assets/close-icon';

type Props = Omit<ComponentPropsWithRef<'button'>, 'children'> & {
	variant?: Variant;
};

type Variant = 'light' | 'dark';

const Button = styled.button<{ variant: Variant }>(({ variant }) => ({
	color: variant === 'light' ? ThemeColors.gray3 : ThemeColors.white,
	cursor: 'pointer',
	width: 32,
	height: 32,
	flexBasis: '0 0 32px',
	padding: 0,
	transition: '0.2s',
	position: 'relative',

	'&:hover': {
		transform: 'scale(1.4)',
	},

	'& > svg': {
		position: 'absolute',
		top: 5,
		left: 5,
	},
}));

export const CloseButton = ({ variant = 'light', ...props }: Props) => (
	<Button variant={variant} {...props}>
		<CloseIcon width={22} />
	</Button>
);
