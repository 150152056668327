import {
	Category,
	CategoryType,
	getCategoryByName,
	Subcategory,
} from '@wyjehau/model';

interface StateFields {
	category: null | Category;
	subcategory: null | Subcategory;
}

export class CategoriesState implements StateFields {
	category: null | Category;
	subcategory: null | Subcategory;

	constructor(fields: StateFields) {
		this.category = fields.category;
		this.subcategory = fields.subcategory;
	}

	toUrlString() {
		const query = new URLSearchParams();

		this.category && query.set('category', this.category.type);
		this.subcategory && query.set('subcategory', this.subcategory.type);

		return query.toString();
	}

	clone() {
		return new CategoriesState(this);
	}

	removeSubcategory() {
		const newState = this.clone();

		newState.subcategory = null;

		return newState;
	}

	removeCategory() {
		const newState = this.clone();

		newState.subcategory = null;
		newState.category = null;

		return newState;
	}

	setCategory(type: CategoryType) {
		const newState = this.clone();

		newState.subcategory = null;
		newState.category = getCategoryByName(type) ?? null;

		return newState;
	}

	setSubCategory(type: string) {
		const newState = this.clone();

		newState.subcategory =
			newState.category?.subcategories.find((subCat) => subCat.type === type) ??
			null;

		return newState;
	}
}
