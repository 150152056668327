// src/category.ts
import { ThemeColors } from "@wyjehau/theme";

// src/place-attribute.ts
var PlaceAttribute = class {
  constructor(name, label) {
    this.name = name;
    this.label = label;
  }
};
var BooleanPlaceAttribute = class extends PlaceAttribute {
  constructor(name, label) {
    super(name, label);
    this.type = "boolean";
  }
};
var WalkAttributes = {
  nearWater: new BooleanPlaceAttribute("nearWater", "Blisko rzeki/jeziora"),
  canWithoutLeash: new BooleanPlaceAttribute("canWithoutLeash", "Mo\u017Cna bez smyczy"),
  bagsOnSite: new BooleanPlaceAttribute("bagsOnSite", "Woreczki czysto\u015Bci na miejscu"),
  muzzleRequired: new BooleanPlaceAttribute("muzzleRequired", "Kaganiec wymagany")
};
var VetAttributes = {
  roundTheClock: new BooleanPlaceAttribute("roundTheClock", "Dy\u017Cur ca\u0142odobowy"),
  dentist: new BooleanPlaceAttribute("dentist", "Stomatologia"),
  usg: new BooleanPlaceAttribute("usg", "USG"),
  rtg: new BooleanPlaceAttribute("rtg", "RTG"),
  cardio: new BooleanPlaceAttribute("cardio", "Kardiolog"),
  surgeon: new BooleanPlaceAttribute("surgeon", "Chirurgia"),
  oculist: new BooleanPlaceAttribute("oculist", "Okulista"),
  skin: new BooleanPlaceAttribute("skin", "Dermatolog"),
  internist: new BooleanPlaceAttribute("internist", "Choroby wewn\u0119trzne"),
  orthopedist: new BooleanPlaceAttribute("orthopedist", "Ortopeda"),
  tomography: new BooleanPlaceAttribute("tomography", "Tomografia komputerowa"),
  labDiag: new BooleanPlaceAttribute("labDiag", "Diagnostyka laboratoryjna"),
  oncology: new BooleanPlaceAttribute("oncology", "Onkologia"),
  obstetrics: new BooleanPlaceAttribute("obstetrics", "Po\u0142o\u017Cnictwo"),
  dietetics: new BooleanPlaceAttribute("dietetics", "Dietetyka"),
  homeService: new BooleanPlaceAttribute("homeService", "Wizyty domowe")
};

// src/subcategory.ts
var Subcategory = class {
  constructor(type, label, attributes) {
    this.type = type;
    this.label = label;
    this.attributes = attributes;
  }
};
var WalkSubcategories = {
  forest: new Subcategory("forest", "Las", Object.values(WalkAttributes)),
  park: new Subcategory("park", "Park", Object.values(WalkAttributes)),
  meadow: new Subcategory("meadow", "\u0141\u0105ki", Object.values(WalkAttributes)),
  run: new Subcategory("run", "Wybieg", Object.values(WalkAttributes))
};
var ServicesSubcategories = {
  vet: new Subcategory("vet", "Weterynarz", Object.values(VetAttributes)),
  groomer: new Subcategory("groomer", "Groomer", []),
  dogHotel: new Subcategory("dogHotel", "Hotel dla ps\xF3w", []),
  dogSchool: new Subcategory("dogSchool", "Szko\u0142a dla ps\xF3w", []),
  breeder: new Subcategory("breeder", "Hodowla ps\xF3w", []),
  zooterapist: new Subcategory("zooterapist", "Zoofizjoterapeuta", []),
  petsitter: new Subcategory("petsitter", "Petsitter", []),
  behaviorist: new Subcategory("behaviorist", "Behawiorysta", []),
  petShop: new Subcategory("pet-shop", "Sklep zoologiczny", [])
};
var SleepSubcategories = {
  apartment: new Subcategory("apartment", "Apartament", []),
  house: new Subcategory("house", "Dom", []),
  room: new Subcategory("room", "Pok\xF3j", []),
  camping: new Subcategory("camping", "Camping", []),
  agrotuoristics: new Subcategory("agrotuoristics", "Agroturystyka", []),
  hotel: new Subcategory("hotel", "Hotel", [])
};
var FoodSubcategories = {
  restaurant: new Subcategory("restaurant", "Restauracja", []),
  coffee: new Subcategory("coffee", "Kawiarnia", []),
  pub: new Subcategory("pub", "Pub / Bar", []),
  icecream: new Subcategory("icecream", "Lodziarnia", [])
};
var OthersSubcategories = {
  bakery: new Subcategory("bakery", "Piekarnia", []),
  grocery: new Subcategory("grocery", "Sklep spo\u017Cywczy", []),
  pharmacy: new Subcategory("pharmacy", "Apteka", []),
  others: new Subcategory("others", "Inne", [])
};

// src/category.ts
var Category = class {
  constructor(type, label, subcategories, icon, color) {
    this.type = type;
    this.label = label;
    this.subcategories = subcategories;
    this.icon = icon;
    this.color = color;
  }
};
var Categories = {
  walk: new Category("walk", "Spacer", Object.values(WalkSubcategories), "Tree", ThemeColors.green2),
  services: new Category("services", "Us\u0142ugi dla psa", Object.values(ServicesSubcategories), "Paw", ThemeColors.blue2),
  sleep: new Category("sleep", "Noclegi", Object.values(SleepSubcategories), "Home", ThemeColors.brown2),
  food: new Category("food", "Gastronomia", Object.values(FoodSubcategories), "Bone", ThemeColors.brown2),
  others: new Category("others", "Inne", Object.values(OthersSubcategories), "Building", ThemeColors.brown2)
};
var categoriesList = Object.values(Categories);
var getCategoryByName = (name) => {
  const model = Categories[name];
  if (!model) {
    throw new Error(`Received invalid category name: "${name}"`);
  }
  return model;
};

// src/google-maps-linker.ts
var GoogleCoordsLinkCreator = class {
  constructor(location) {
    this.location = location;
  }
  getStaticPlace(zoom = 17) {
    return `https://www.google.com/maps/@${this.location.lat},${this.location.lng},${zoom}z`;
  }
  getDirectionsToPlace(zoom = 15) {
    return `https://www.google.com/maps/dir//${this.location.lat},${this.location.lng}/@${this.location.lat},${this.location.lng},${zoom}z`;
  }
};
export {
  BooleanPlaceAttribute,
  Categories,
  Category,
  FoodSubcategories,
  GoogleCoordsLinkCreator,
  OthersSubcategories,
  PlaceAttribute,
  ServicesSubcategories,
  SleepSubcategories,
  Subcategory,
  VetAttributes,
  WalkAttributes,
  WalkSubcategories,
  categoriesList,
  getCategoryByName
};
