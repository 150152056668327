import * as React from 'react';
import { HTMLAttributes, useCallback } from 'react';
import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';

import { useLeafletMap } from '../../leaflet-map-context';
import { Plus } from '../../icons/assets/plus';
import { Minus } from '../../icons/assets/minus';

const Container = styled.div({
	borderRadius: 10,
	overflow: 'hidden',
	display: 'grid',
	gridTemplateRows: '36px 36px',
	width: '36px',
	gridGap: 1,
});

const Button = styled.button({
	background: ThemeColors.blue3,
	display: 'block',
	color: 'white',
	fontSize: 24,
	width: '36px',
	height: '36px',
	padding: 0,
	textAlign: 'center',
});

export const ZoomControls = (props: HTMLAttributes<HTMLDivElement>) => {
	const map = useLeafletMap();

	const zoomInCallback = useCallback(() => {
		map?.zoomIn();
	}, [map]);

	const zoomOutCallback = useCallback(() => {
		map?.zoomOut();
	}, [map]);

	if (!map) {
		return null;
	}

	return (
		<Container {...props}>
			<Button onClick={zoomInCallback}>
				<Plus width={14} />
			</Button>
			<Button onClick={zoomOutCallback}>
				<Minus width={14} />
			</Button>
		</Container>
	);
};
