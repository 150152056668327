import * as React from 'react';
import { SVGProps } from 'react';

export const Plus = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8 1v14M1 8h14"
			stroke="#fff"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
	</svg>
);
