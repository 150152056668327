import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { CategoryType, getCategoryByName } from '@wyjehau/model';

import { CategoriesState } from '../categories-state';

/**
 * TODO
 * This sucks, figure out better approach
 */
export const useAppNavigation = () => {
	const { push, query: _query, ...router } = useRouter();

	const query = _query as {
		id?: string;
		slug?: string;
		category?: CategoryType;
		subcategory?: string;
	};

	const categoriesState = useMemo(() => {
		const categoryModel = query.category
			? getCategoryByName(query.category as CategoryType)
			: null;

		return new CategoriesState({
			category: categoryModel,
			subcategory:
				categoryModel?.subcategories.find(
					(subcat) => subcat.type === query.subcategory,
				) ?? null,
		});
	}, [query]);

	return useMemo(() => {
		return {
			goToNewPlaceCreator() {
				return push('/new-place');
			},
			closeActivePlace() {
				return push({
					pathname: '/map',
					query: categoriesState.toUrlString(),
				});
			},
			setCategory(category: CategoryType | null) {
				let newState = categoriesState.clone();

				if (category) {
					newState = newState.setCategory(category);
				} else {
					newState = newState.removeCategory();
				}

				if (query.id && query.slug) {
					return push({
						query: newState.toUrlString(),
						pathname: `/map/place/${query.id}/${encodeURIComponent(
							query.slug.toLocaleLowerCase(),
						)}`,
					});
				} else {
					return push({
						pathname: '/map',
						query: newState.toUrlString(),
					});
				}
			},
			setSubcategory(subcategory: string | null) {
				let newState = categoriesState.clone();

				if (subcategory) {
					newState = newState.setSubCategory(subcategory);
				} else {
					newState = newState.removeSubcategory();
				}

				if (query.id && query.slug) {
					return push({
						query: newState.toUrlString(),
						pathname: `/map/place/${query.id}/${encodeURIComponent(
							query.slug.toLocaleLowerCase(),
						)}`,
					});
				} else {
					return push({
						pathname: '/map',
						query: newState.toUrlString(),
					});
				}
			},
			goToPlaceDetails(id: string, slugOrName: string) {
				return push({
					query: categoriesState.toUrlString(),
					pathname: `/map/place/${id}/${encodeURIComponent(
						slugOrName.toLocaleLowerCase(),
					)}`,
				});
			},
			goToMap() {
				return push({
					pathname: '/map',
				});
			},
		};
	}, [push, categoriesState, query]);
};
