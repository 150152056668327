import * as React from 'react';
import { HTMLAttributes } from 'react';

export const LocationIcon = (props: HTMLAttributes<SVGSVGElement>) => (
	<svg
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.333 1.5h-3.5A2.333 2.333 0 0 0 1.5 3.833v3.5m21 0v-3.5A2.333 2.333 0 0 0 20.167 1.5h-3.5m0 21h3.5a2.333 2.333 0 0 0 2.333-2.333v-3.5m-21 0v3.5A2.333 2.333 0 0 0 3.833 22.5h3.5"
			stroke="currentColor"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.195 8.289c-.178 1.13-.977 1.941-1.784 1.814s-1.316-1.146-1.137-2.276c.178-1.13.977-1.941 1.783-1.814.807.128 1.316 1.147 1.138 2.276Zm-4.909 1.876c.807-.129 1.314-1.148 1.134-2.278-.18-1.129-.98-1.94-1.787-1.81-.806.128-1.314 1.148-1.133 2.277.18 1.129.98 1.94 1.786 1.811Zm-1.738-.004c.252.953-.095 1.872-.776 2.052-.68.18-1.437-.446-1.69-1.4-.252-.953.095-1.871.776-2.052.681-.18 1.437.447 1.69 1.4Zm2.892 6.537c.607-.179 1.44.256 2.287.699.363.19.728.38 1.08.525 1.173.483 2.142-1.377 1.53-2.856-.568-1.373-2.244-3.622-3.825-3.877-1.582-.255-2.55.306-4.03 1.938-1.48 1.632-1.887 3.928-.663 4.438.879.366 1.866-.095 2.71-.489.33-.154.64-.298.911-.378Zm4.436-4.527c.647.253 1.458-.276 1.811-1.181.354-.906.117-1.844-.53-2.097-.647-.253-1.458.276-1.812 1.182-.353.905-.116 1.844.53 2.097Z"
			fill="currentColor"
		/>
	</svg>
);
